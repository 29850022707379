function intersection (setA, setB) {
  const _intersection = new Set();
  for (const elem of setB) {
    if (setA.has(elem)) _intersection.add(elem);
  }

  return _intersection;
}

function shuffleArray (array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function fixDigits (entity) {
  return entity
    .replace(/1/g, '₁')
    .replace(/2/g, '₂')
    .replace(/3/g, '₃')
    .replace(/4/g, '₄')
    .replace(/5/g, '₅')
    .replace(/6/g, '₆')
    .replace(/7/g, '₇')
    .replace(/8/g, '₈')
    .replace(/9/g, '₉')
    .replace(/0/g, '₀')
}


export { intersection, shuffleArray, fixDigits };
