<template>
  <div v-if="isLoaded">
    <div v-if="isURL">
      <img :src="url"
           :style="imgStyle"
           class="mx-auto my-2"
           style="max-height: 40vh;"
           alt="" />
      <div v-if="!readonly"
           class="flex justify-center">
        <button class="px-4 py-2 rounded-l text-white font-semibold"
                :class="imgSize === 'sm' ? `bg-gray-600` : `bg-gray-400 opacity-70`"
                @click="handleClick">sm</button>
        <button class="px-4 py-2 text-white font-semibold"
                :class="imgSize === 'md' ? `bg-gray-600` : `bg-gray-400 opacity-70`"
                @click="handleClick">md</button>
        <button class="px-4 py-2 rounded-r text-white font-semibold"
                :class="imgSize === 'lg' ? `bg-gray-600` : `bg-gray-400 opacity-70`"
                @click="handleClick">lg</button>
      </div>
    </div>
    <div v-else-if="isTab">
      <table class="mx-auto text-center bg-white" :style="tabStyle">
        <tr>
          <th v-for="cell in table[0].content"
              :key="cell.key"
              class="p-2 border bg-gray-200">{{ cell.content }}</th>
        </tr>
        <tr v-for="row in table.slice(1)" :key="row.key">
          <td v-for="cell in row.content"
              :key="cell.key"
              class="p-2 border">{{ cell.content }}</td>
        </tr>
      </table>
    </div>
    <div v-else-if="containsFormula" v-html="formulaEl" class="block pb-2"></div>
    <div v-else-if="containsReaction" v-html="reactionEl" class="block pb-2"></div>
    <div v-else-if="isChain" v-html="chainEl" class="flex justify-center"></div>
    <p v-else-if="readonly" class="whitespace-pre-wrap text-lg">{{ content }}</p>
    <textarea v-else
              :value="content"
              ref="textarea"
              class="text-lg w-full overflow-y-hidden bg-transparent"
              style="resize: none;"
              @blur="handleInput"></textarea>
  </div>
</template>

<script>
const validUrl = require('valid-url');
const axios = require('axios');
const { nanoid } = require('nanoid');
import { mapState } from 'vuex';
import { fixDigits } from '@/assets/functions';

export default {
  props: {
    content: String,
    svgs: Object,
    readonly: Boolean,
  },
  data () {
    return {
      isURL: validUrl.is_uri(this.content),
      containsFormula: /#\S+/g.test(this.content),
      containsReaction: /R{/.test(this.content),
      isChain: /^CHAIN{/.test(this.content),
      imgSize: this.content.slice(-6, -4),
      tabSize: this.content.slice(-2),
      isTab: this.content.startsWith('TAB_'),
      table: undefined,
      buttons: ['sm', 'md', 'lg'],
      isLoaded: false,
    };
  },
  computed: {
    // ...mapState({
    //   svgs: state => state.task.svgs,
    // }),
    imgStyle () { return this.calcSize(this.imgSize); },
    tabStyle () { return this.calcSize(this.tabSize); },
    url () { return this.isURL ? this.content.slice(0, -7) + this.content.slice(-4) : ''; },
    reactionId () { return this.containsReaction ? this.content.match(/R{.*?}/)[0].replace(/[^А-Яа-яA-Za-z0-9,]/g, '') : ''; },
    chainId () { return this.isChain ? this.content.replace(/[^А-Яа-яA-Za-z0-9,]/g, '') : ''; },
    chainEl () {
      return this.$route.query.str === 'true'
        ? this.content.replace(/CHAIN\{|}/g, '')
        : this.isChain ? atob(this.svgs[this.chainId]) : ''
    },
    reactionEl () {
      if (this.containsReaction) {
        const svgized = this.$route.query.str === 'true'
          ? this.content.replace(/R\{|}/g, '')
          : this.content.replace(/R{.*?}/g, `</p>${atob(this.svgs[this.reactionId])}`);
        return `<p class="inline-block align-bottom text-lg mr-1">${svgized}`.replace('<svg', '<svg class="inline-block"');
      }
      return '';
    },
    formulaEl () {
      if (this.containsFormula) {
        const matches = this.content.matchAll(/#\S+/g);
        let currentIdx = 0;
        const arr = [];
        for (const match of matches) {
          if (this.$route.query.str === 'true') {
            const fixedFormula = fixDigits(match[0].slice(1));
            arr.push(`<p class="inline-block align-bottom text-lg">${this.content.slice(currentIdx, match.index)}</p>`);
            arr.push(`<p class="inline-block align-bottom text-lg">${fixedFormula}</p>`);
            currentIdx = match.index + match[0].length;
          } else {
            const formulaId = match[0].replace(/[^А-Яа-яA-Za-z0-9,]/g, '');
            arr.push(`<p class="inline-block align-bottom text-lg">${this.content.slice(currentIdx, match.index)}</p>`);
            arr.push(match[0].replace(/#\S+/, `${atob(this.svgs[formulaId]).replace('<svg', '<svg class="inline-block align-bottom"')}`));
            currentIdx = match.index + match[0].length;
          }
        }
        arr.push(`<p class="inline-block align-bottom text-lg">${this.content.slice(currentIdx)}</p>`);

        return arr.join('\n');
      }
      return '';
    },
  },
  created () {
    // if (this.isChain) console.log(JSON.stringify(this.chainEl));
    if (!this.isTab) return this.isLoaded = true;

    const tabName = this.content.replace(/^TAB_/, '').replace(/(_lg|_md|_sm)$/, '');
    this.$store.dispatch('getTable', tabName)
      .then(() => {
        this.table = this.$store.state.tables[tabName].split('\n')
          .map(r => ({
            content: r.split('\t').map(c => ({
              content: c,
              key: nanoid(8),
            })),
            key: nanoid(8),
          }));
        this.isLoaded = true;
      });
  },
  mounted () {
    const textarea = this.$refs.textarea;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = (textarea.scrollHeight + 2) + 'px';
    }
  },
  updated () {
    const textarea = this.$refs.textarea;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = (textarea.scrollHeight + 2) + 'px';
    }
  },
  methods: {
    handleInput (e) {
      const message = e.target.value;
      this.$emit('input', message);
    },
    handleClick (e) {
      const size = e.target.innerHTML;
      this.imgSize = size;
      this.$emit('input', this.content.slice(0, -6) + size + this.content.slice(-4));
    },
    calcSize (imgSize) {
      if (imgSize === 'sm') return 'max-width: 30%; max-height: 20vh;';
      if (imgSize === 'md') return 'max-width: 55%; max-height: 30vh;';
      if (imgSize === 'lg') return 'max-width: 80%';
      return '';
    },
  },
}
</script>
