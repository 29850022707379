<template>
  <div :class="readonly ? '' : 'mb-2 p-3 rounded-lg bg-gray-100'">
    <p v-if="!readonly"
       class="mb-2 text-sm font-bold text-gray-600">{{ title }}</p>
    <t-b v-for="(block, idx) in smallBlocks"
         :content="block.content"
         :svgs="svgs"
         :readonly="readonly"
         :key="block.key"
         @input="handleInput($event, idx)"></t-b>
    <p v-if="readonly">&nbsp;</p>
  </div>
</template>

<script>
const { nanoid } = require('nanoid');
import TB from '../components/TaskBlock.vue';

export default {
  props: {
    content: String,
    svgs: Object,
    readonly: Boolean,
    idx: Number,
  },
  components: {
    TB,
  },
  data () {
    return {
      titles: ['Вопрос', 'Варианты ответа', 'Классификаторы', 'Ответ'],
      baseLink: 'https://storage.yandexcloud.net/biology/task_imgs/',
      imgExtension: '.jpg',
    };
  },
  computed: {
    title () {
      if (this.idx === -1) return this.titles[this.titles.length - 1];
      return this.titles[this.idx];
    },
    smallBlocks () { return this.toSmallBlocks(this.content); },
  },
  methods: {
    toSmallBlocks (largeBlock) {
      const blocks = [];
      let block = [];
      const smallBlocks = largeBlock.split('\n');

      for (let [sidx, sb] of smallBlocks.entries()) {
        if (sb.startsWith('IMG_') || sb.startsWith('TAB_') || /#\S+/g.test(sb) || /R{.*?}/g.test(sb) || sb.startsWith('CHAIN{')) {
          if (block.length > 0) {
            blocks.push({ key: nanoid(), content: block.join('\n') });
            block = [];
          }

          if (sb.startsWith('IMG_')) {
            blocks.push({ key: nanoid(), content: `${sb.replace(/^IMG_/, this.baseLink)}${this.imgExtension}` });
          } else if (sb.startsWith('TAB_') || /#\S+/g.test(sb) || /R{.*?}/g.test(sb) || sb.startsWith('CHAIN{')) {
            blocks.push({ key: nanoid(), content: sb });
          }
        } else {
          const superscripted = sb
            .replace(/\^1/g, '¹')
            .replace(/\^2/g, '²')
            .replace(/\^3/g, '³')
            .replace(/\^4/g, '⁴')
            .replace(/\^5/g, '⁵')
            .replace(/\^6/g, '⁶')
            .replace(/\^7/g, '⁷')
            .replace(/\^8/g, '⁸')
            .replace(/\^9/g, '⁹')
            .replace(/\^0/g, '⁰')
          block.push(superscripted);
          if (sidx + 1 === smallBlocks.length) blocks.push({ key: nanoid(), content: block.join('\n') });
        }
      }

      return blocks;
    },
    handleInput(e, idx) {
      const blocks = [...this.smallBlocks].map(sb => sb.content);
      blocks[idx] = e;
      const updatedBlock = blocks.join('\n')
        .replaceAll(this.baseLink, 'IMG_')
        .replaceAll(this.imgExtension, '');
      this.$emit('input', updatedBlock);
    },
  },
}
</script>
