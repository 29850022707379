<template>
  <div>
    <div v-if="subject === 'bio'"
         class="wrapper-note absolute bottom-0 right-0 p-4 m-4 bg-gray-100 rounded-xl text-sm">
      <div class="wrapper-note-left">
        <p>B1</p>
        <p>Т</p>
        <p>схема</p>
        <p>B2</p>
        <p>Т</p>
        <p>таблица 2x3</p>
        <p>B3</p>
        <p>Т</p>
        <p>задача по цитологии</p>
        <p>B4</p>
        <p>В</p>
        <p>цитология</p>
        <p>B5</p>
        <p>С</p>
        <p>цитология</p>
        <p>B6</p>
        <p>Т</p>
        <p>задача по генетике</p>
        <p>B7</p>
        <p>В</p>
        <p>развитие организмов</p>
        <p>B8</p>
        <p>С</p>
        <p>развитие организмов</p>
        <p>B9</p>
        <p>В</p>
        <p>царства</p>
        <p>B10</p>
        <p>С</p>
        <p>царства</p>
        <p>B11</p>
        <p>П</p>
        <p>таксономия</p>
        <p>B12</p>
        <p>В</p>
        <p>анатомия</p>
        <p>B13</p>
        <p>С</p>
        <p>анатомия</p>
        <p>B14</p>
        <p>П</p>
        <p>анатомия</p>
      </div>
      <div class="wrapper-note-right">
        <p>B15</p>
        <p>В</p>
        <p>эволюция</p>
        <p>B16</p>
        <p>С</p>
        <p>эволюция</p>
        <p>B17</p>
        <p>В</p>
        <p>экология</p>
        <p>B18</p>
        <p>С</p>
        <p>экология</p>
        <p>B19</p>
        <p>П</p>
        <p>любая, кроме B11 и B14</p>
        <p>B20</p>
        <p>В</p>
        <p>расстановка терминов</p>
        <p>B21</p>
        <p>В</p>
        <p>график / таблица</p>
        <p>C1</p>
        <p>РО</p>
        <p>простой вопрос</p>
        <p>C2</p>
        <p>РО</p>
        <p>изображение</p>
        <p>C3</p>
        <p>РО</p>
        <p>ошибки</p>
        <p>C4</p>
        <p>РО</p>
        <p>сложный вопрос по царствам</p>
        <p>C5</p>
        <p>РО</p>
        <p>сложный вопрос по эво/эко</p>
        <p>C6</p>
        <p>РО</p>
        <p>задача по цитологии</p>
        <p>C7</p>
        <p>РО</p>
        <p>задача по генетике</p>
      </div>
    </div>
    <div v-else-if="subject === 'chem'"
         class="wrapper-note absolute bottom-0 right-0 p-4 m-4 bg-gray-100 rounded-xl text-sm">
      <div class="wrapper-note-left">
        <p>B1</p>
        <p>В</p>
        <p>электронная конфигурация</p>
        <p>B2</p>
        <p>В</p>
        <p>периодический закон</p>
        <p>B3</p>
        <p>В</p>
        <p>степень ок. и валентность</p>
        <p>B4</p>
        <p>В</p>
        <p>связи и решетки</p>
        <p>B5</p>
        <p>С</p>
        <p>неорг. номенклатура</p>
        <p>B6</p>
        <p>В</p>
        <p>простые в-ва и оксиды</p>
        <p>B7</p>
        <p>В</p>
        <p>гидроксиды и соли</p>
        <p>B8</p>
        <p>С</p>
        <p>неорг. микс (возможность)</p>
        <p>B9</p>
        <p>С</p>
        <p>неорг. микс (продукты)</p>
        <p>B10</p>
        <p>В</p>
        <p>неорг. цепочка</p>
        <p>B11</p>
        <p>С</p>
        <p>орг. номенклатура</p>
        <p>B12</p>
        <p>В</p>
        <p>теория строения органики</p>
        <p>B13</p>
        <p>В</p>
        <p>химия углеводородов</p>
        <p>B14</p>
        <p>В</p>
        <p>химия кислородорганики</p>
        <p>B15</p>
        <p>В</p>
        <p>амины и биоорганика</p>
        <p>B16</p>
        <p>С</p>
        <p>химия углеводородов</p>
        <p>B17</p>
        <p>С</p>
        <p>химия кислородорганики</p>
        <p>B18</p>
        <p>В</p>
        <p>орг. цепочка</p>
      </div>
      <div class="wrapper-note-right">
        <p>B19</p>
        <p>В</p>
        <p>классификация реакций</p>
        <p>B20</p>
        <p>В</p>
        <p>скорость реакций</p>
        <p>B21</p>
        <p>С</p>
        <p>ОВР</p>
        <p>B22</p>
        <p>С</p>
        <p>электролиз</p>
        <p>B23</p>
        <p>С</p>
        <p>гидролиз</p>
        <p>B24</p>
        <p>С</p>
        <p>равновесие</p>
        <p>B25</p>
        <p>С</p>
        <p>качественные реакции</p>
        <p>B26</p>
        <p>С</p>
        <p>лаба, пром, полимеры</p>
        <p>B27</p>
        <p>Ч</p>
        <p>расчет массовой доли</p>
        <p>B28</p>
        <p>Ч</p>
        <p>расчет объемов и термохимия</p>
        <p>B29</p>
        <p>Ч</p>
        <p>расчет массы или объема</p>
        <p>C1</p>
        <p>РО</p>
        <p>ОВР</p>
        <p>C2</p>
        <p>РО</p>
        <p>реакции ионного обмена</p>
        <p>C3</p>
        <p>РО</p>
        <p>неорг. цепочка</p>
        <p>C4</p>
        <p>РО</p>
        <p>орг. цепочка</p>
        <p>C5</p>
        <p>РО</p>
        <p>расчет значения</p>
        <p>C6</p>
        <p>РО</p>
        <p>установление формулы</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      subject: String,
    },
    data() {
      return {

      }
    },
  }
</script>

<style scoped>
  .wrapper-note {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: 1rem;
  }

  .wrapper-note-left {
    display: grid;
    grid-template-columns: 2fr 1fr 10fr;
    grid-template-rows: auto;
    grid-column-gap: 1rem;
    grid-row-gap: 0.15rem;
  }

  .wrapper-note-right {
    display: grid;
    grid-template-columns: 2fr 1fr 10fr;
    grid-template-rows: auto;
    grid-column-gap: 1rem;
    grid-row-gap: 0.15rem;
  }
</style>
