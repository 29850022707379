<template>
  <div class="">
    <div class="w-full flex justify-start pl-12 py-1">

      <div class="flex flex-col mx-2">
        <label v-if="!streaming" for="subject">Предмет</label>
        <select name="subject"
                id="subject"
                class="w-32"
                @input="loadFields">
          <option v-for="option in options.subjects"
                  :key="option.text"
                  v-bind:value="option.value">
            {{ option.text }}
          </option>
        </select>
      </div>

      <div class="flex flex-col mx-2">
        <label v-if="!streaming" for="chapter">Раздел</label>
        <select name="chapter"
                id="chapter"
                class="w-48"
                @input="loadThemes">
          <option disabled value="">—</option>
          <option v-for="option in options.chapters"
                  :key="option.text"
                  v-bind:value="option.value">
            {{ option.text }}
          </option>
        </select>
      </div>

      <div class="flex flex-col mx-2">
        <label v-if="!streaming" for="theme">Тема</label>
        <select v-model="query.theme"
                name="theme"
                id="theme"
                class="w-64">
          <option value="">—</option>
          <option v-for="option in options.themes"
                  :key="option.text"
                  v-bind:value="option.value">
            {{ option.text }}
          </option>
        </select>
      </div>

      <div class="flex flex-col mx-2">
        <label v-if="!streaming" for="kind">Тип</label>
        <select v-model="query.kind"
                name="kind"
                id="kind"
                class="w-44">
          <option value="">—</option>
          <option v-for="option in options.kinds"
                  :key="option.text"
                  v-bind:value="option.value">
            {{ option.text }}
          </option>
        </select>
      </div>

      <div class="flex flex-col mx-2">
        <label v-if="!streaming" for="part">Часть</label>
        <select v-model="query.part"
                name="part"
                id="part"
                class="w-16">
          <option v-for="option in options.parts"
                  :key="option.text"
                  v-bind:value="option.value">
            {{ option.text }}
          </option>
        </select>
      </div>

      <div class="flex flex-col mx-2">
        <label v-if="!streaming" for="position">Линия</label>
        <select v-model="query.position"
                name="position"
                id="position"
                class="w-16">
          <option value="">—</option>
          <option v-for="option in options.positions"
                  :key="option.text"
                  v-bind:value="option.value">
            {{ option.text }}
          </option>
        </select>
      </div>

      <button class="mx-6 px-6 bg-green-500 text-white font-semibold rounded-lg"
              @click="search">
        Найти
      </button>
    </div>

    <div class="pl-12 p-5 xl:w-2/3">
      <div v-for="task in tasks"
           :key="task.tid"
           class="">
        <t-g v-for="(block, idx) in task.qBlocks"
             :content="block.content"
             :svgs="task.svgs"
             :readonly="true"
             :idx="idx"
             :key="block.key"></t-g>
        <p>&nbsp;</p>
      </div>
    </div>

    <div class="p-5 w-2/3">
      <div v-for="task in tasks"
           :key="task.tid"
           class="">
        <t-g v-if="task.answer"
             :content="task.answer"
             :readonly="true"
             :idx="-1"></t-g>
      </div>
    </div>
  </div>
</template>

<script>
import TG from '../components/TaskGroup.vue';
import { bioKinds, bioPositions, bioThemes, chemKinds, chemPositions, chemThemes } from '../assets/taskDicts';
import axios from 'axios';
import { nanoid } from 'nanoid';
import { shuffleArray } from '../assets/functions';

export default {
  components: {
    TG,
  },
  data () {
    return {
      streaming: true,
      tasks: [],
      query: {
        subject: '',
        chapter: '',
        theme: '',
        kind: '',
        position: '',
        part: '',
      },
      options: {
        subjects: [
          { text: '—', value: '' },
          { text: 'Биология', value: 'bio' },
          { text: 'Химия', value: 'chem' },
        ],
        chapters: [],
        themes: [],
        kinds: [],
        positions: [],
        parts: [
          { text: '—', value: '' },
          { text: 'Часть B', value: 'B' },
          { text: 'Часть С', value: 'C' },],
      }
    };
  },
  methods: {
    loadFields (e) {
      this.query.subject = e.target.value;
      if (this.query.subject === 'bio') {
        this.options.chapters = bioThemes.map(bt => ({ text: bt.chapter, value: bt.chapter }));
        this.options.kinds = Object.entries(bioKinds).map(bk => ({ text: bk[1], value: bk[0] }));
        this.options.positions = Array.from(bioPositions).map(bp => ({ text: bp, value: bp }));
      } else if (this.query.subject === 'chem') {
        this.options.chapters = chemThemes.map(bt => ({ text: bt.chapter, value: bt.chapter }));
        this.options.kinds = Object.entries(chemKinds).map(bk => ({ text: bk[1], value: bk[0] }));
        this.options.positions = Array.from(chemPositions).map(bp => ({ text: bp, value: bp }));
      }
    },
    loadThemes (e) {
      this.query.chapter = e.target.value;
      if (this.query.subject === 'bio') {
        this.options.themes = bioThemes.filter(bt => bt.chapter === this.query.chapter)[0]
          .themes.map(t => ({ text: t, value: t }));
      } else if (this.query.subject === 'chem') {
        this.options.themes = chemThemes.filter(bt => bt.chapter === this.query.chapter)[0]
          .themes.map(t => ({ text: t, value: t }));
      }
    },
    search () {
      const params = new URLSearchParams();
      if (!!this.query.subject) params.append('subject', this.query.subject);
      if (!!this.query.theme) params.append('theme', this.query.theme);
      else if (!!this.query.chapter) params.append('theme', this.query.chapter);
      if (!!this.query.kind) params.append('kind', this.query.kind);
      if (!!this.query.position) params.append('position', this.query.position);
      if (!!this.query.part) params.append('part', this.query.part);

      const testingTasks = [
        {
          "_id": "6107f4a0c71a0356d63a595d",
          "theme": [
            "Анатомия",
            "Ткани"
          ],
          "tid": "s0atwpn8",
          "task": "Все перечисленные ниже понятия используют для описания изображенной на рисунке клетки. Определите два понятия, «выпадающие» из общего списка, и запишите в таблицу цифры, под которыми они указаны.\nIMG_PR_NEURON_md\n\n1) сократимость\n2) дендриты\n3) рецепторные белки\n4) миелиновая оболочка\n5) деление митозом",
          "answer": "15",
          "noshuffle": false,
          "part": "B",
          "subject": "bio",
          "__v": 0,
          "createdAt": "2021-08-02T13:35:28.774Z",
          "updatedAt": "2021-08-02T13:42:19.868Z",
          "hasError": false,
          "hasWarning": false,
          "kind": "pick",
          "position": "B4"
        },
        {
          "_id": "6107f4a0c71a0356d63a5965",
          "theme": [
            "Анатомия",
            "Кровь"
          ],
          "tid": "yt5kjzl2",
          "task": "Для эритроцитов крови человека характерно:\n\n1) наличие в мембране белков аглютиногенов\n2) двояковыпуклая форма\n3) амебоидное движение\n4) наличие гемоглобина\n5) отсутствие ядра\n6) участие в формировании иммунитета",
          "answer": "145",
          "noshuffle": false,
          "part": "B",
          "subject": "bio",
          "__v": 0,
          "createdAt": "2021-08-02T13:35:28.775Z",
          "updatedAt": "2021-08-02T13:43:13.632Z",
          "hasError": false,
          "hasWarning": false,
          "kind": "pick",
          "position": "B12"
        },
        {
          "_id": "6107f4a0c71a0356d63a5966",
          "theme": [
            "Анатомия",
            "Нервная система"
          ],
          "tid": "j4qm3tgo",
          "task": "Установите соответствие между особенностями регуляции и ее видами.\n\nОСОБЕННОСТИ РЕГУЛЯЦИИ\nA) воздействует на определенный орган\nБ) осуществляется через кровь\nB) происходит с участием гормонов\nГ) осуществляется электрическим импульсом\nД) воздействует на весь организм\n\nВИДЫ РЕГУЛЯЦИИ\n1) нервная\n2) гуморальная",
          "answer": "12212",
          "noshuffle": false,
          "part": "B",
          "subject": "bio",
          "__v": 0,
          "createdAt": "2021-08-02T13:35:28.775Z",
          "updatedAt": "2021-08-02T13:43:18.588Z",
          "hasError": false,
          "hasWarning": false,
          "kind": "match",
          "position": "B13"
        }];

      // function shuffleOptions (pta, an) {
      //   const ProcessingEntity = function (task, answer, kind) {
      //     this.origin = task.split('\n\n');
      //     this.answer = answer;
      //     this.kind = kind;
      //     this.noShuffle = /NO_SHUFFLE/.test(task);
      //     this.stripped = { title: '', options: '' };
      //     this.modAnswer = '';
      //     this.letterCounter = false;
      //   }
      //
      //   ProcessingEntity.prototype.strip = function () {
      //     if (!this.noShuffle && ['pick', 'match', 'sequence'].includes(this.kind)) {
      //
      //     }
      //   }
      // }

      // let counter = 1;
      // this.tasks = shuffleArray(testingTasks).map(tt => {
      //   const processedTask = {
      //     ...tt,
      //     qBlocks: tt.task.split('\n\n')
      //       .map(lb => ({ content: lb, key: nanoid() })),
      //   };
      //   processedTask.qBlocks[0].content = `${counter}. ${processedTask.qBlocks[0].content}`;
      //   counter += 1;
      //
      //   return processedTask;
      // });

      const existingTasks = this.tasks;
      axios.get(`/api/v1/tasks/`, { params })
        .then((response) => {
          let counter = 1;
          this.tasks = shuffleArray([...existingTasks, ...response.data]).map(tt => {
            const processedTask = {
              ...tt,
              qBlocks: tt.task.split('\n\n')
                .map(lb => ({ content: lb, key: nanoid() })),
            };
            processedTask.qBlocks[0].content = `${counter}. ${processedTask.qBlocks[0].content}`;
            counter += 1;

            return processedTask;
          });

          return this.tasks;
        });
    },
  }
}
</script>

<style scoped lang="scss">

</style>
