<template>
  <div v-if="isLoaded" class="wrapper">
    <task-note :subject="task.subject"></task-note>
    <div class="wrapper-task p-4">
      <t-g v-for="(block, idx) in task.largeBlocks"
           :content="block.content"
           :svgs="task.svgs"
           :idx="idx"
           :key="block.key"
           @input="handleTaskInput($event, idx)"></t-g>
      <t-g v-if="task.answer"
           :content="task.answer"
           :idx="-1"
           @input="handleTaskInput($event, -1)"></t-g>
    </div>
    <div class="wrapper-info p-4">
      <div class="wrapper-info-head">
        <div class="wrapper-info-basic flex">
          <div class="m-2 px-4 py-2 bg-gray-400 text-white font-semibold rounded-xl">{{ task.part }}</div>
          <div class="m-2 px-4 py-2 bg-gray-400 text-white font-semibold rounded-xl">{{ task.tid }}</div>
          <div class="m-2 px-4 py-2 bg-gray-400 text-white font-semibold rounded-xl">{{ count.current }}/{{ count.total }}</div>
        </div>
        <div class="wrapper-info-flags flex">
          <div class="self-center m-2 px-4 py-2 bg-gray-500 text-white font-semibold rounded-xl">
            <input v-model="task.hasWarning"
                   type="checkbox"
                   id="hasWarning"
                   name="hasWarning">
            <label for="hasWarning"
                   class="ml-1">🚩</label>
          </div>
          <div class="self-center m-2 px-4 py-2 bg-gray-500 text-white font-semibold rounded-xl">
            <input v-model="task.hasError"
                   type="checkbox"
                   id="hasError"
                   name="hasError">
            <label for="hasError"
                   class="ml-1">⚠️</label>
          </div>
        </div>
        <div class="wrapper-info-navigation flex">
          <button v-if="previousTask"
                  class="m-2 px-4 py-2 bg-gray-400 text-white font-semibold rounded-xl"
                  @click="prevTask">←</button>
          <button class="m-2 px-4 py-2 bg-gray-400 text-white font-semibold rounded-xl"
                  @click="nextTask">→</button>
        </div>
      </div>
      <i-g :options="Object.values(kinds)"
           :value="task.kind"
           :color="'blue'"
           @input="handleInput($event, 'kind')"></i-g>
      <i-g v-if="task.kind"
           :options="chapters"
           :value="task.chapter"
           :color="'green'"
           @input="handleInput($event,'chapter')"></i-g>
      <i-g v-if="task.kind && task.chapter"
           :options="themes"
           :value="task.topic"
           :color="'green'"
           @input="handleInput($event,'topic')"></i-g>
      <i-g v-if="task.kind && task.chapter && task.topic"
           :options="positionOptions"
           :value="task.position"
           :color="'yellow'"
           @input="handleInput($event,'position')"></i-g>
      <button v-if="task.kind && task.chapter && task.topic && task.position"
              class="mt-6 px-6 py-3 bg-indigo-900 text-2xl text-white font-semibold rounded-xl"
              @click="nextTask(false)">📨&nbsp;&nbsp;Сохранить</button>
      <button v-if="task.kind && task.chapter && task.topic && task.position"
              class="ml-2 mt-6 px-6 py-3 bg-pink-600 text-2xl text-white font-semibold rounded-xl"
              @click="nextTask(true)">🙈&nbsp;&nbsp;Сохранить и скрыть</button>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
const { nanoid } = require('nanoid');
import { mapState } from 'vuex';
import TG from '../components/TaskGroup.vue';
import IG from '../components/InfoGroup.vue';
import TaskNote from '../components/TaskNote';
import { bioKinds, bioPositions, bioPositionsRules, bioThemes } from '../assets/taskDicts';
import { intersection } from '../assets/functions';

export default {
  components: {
    TG,
    IG,
    TaskNote,
  },
  props: {
    tid: String,
  },
  data () {
    return {
      isLoaded: false,
      kinds: bioKinds,
      revKinds: Object.assign({}, ...Object.entries(bioKinds).map(([a,b]) => ({ [b]: a }))),
      chapters: bioThemes.map(bt => bt.chapter).sort((a,b) => a.localeCompare(b)),
      themes: [],
    }
  },
  computed: {
    ...mapState({
      task: state => state.task,
      previousTask: state => state.previousTask,
      count: state => state.count,
    }),
    positionOptions () {
      const posFromKind = this.task.kind ? bioPositionsRules[this.task.kind] : bioPositions;
      const posFromChapter = this.task.chapter ? bioPositionsRules[this.task.chapter] : bioPositions;

      return Array.from(intersection(posFromKind, posFromChapter));
    },
  },
  created () {
    document.title = 'ЕГЭ по био — сортировка заданий';

    if (this.tid) Promise.all([
      this.$store.dispatch('getTaskById', { taskId: this.tid }),
      this.$store.dispatch('updateCount', { subject: 'bio' }),
    ]).then(() => this.isLoaded = true);
    else Promise.all([
      this.$store.dispatch('getTaskWithMissingInfo', { subject: 'bio' }),
      this.$store.dispatch('updateCount', { subject: 'bio' }),
    ]).then(() => this.isLoaded = true);
  },
  methods: {
    handleTaskInput (e, idx) {
      if (idx === -1) return this.$store.commit('updateTaskParam', { param: 'answer', value: e });

      const largeBlocks = this.task.largeBlocks;
      largeBlocks[idx].content = e;
      this.$store.commit('updateTaskParam', { param: 'largeBlocks', value: largeBlocks });
    },
    handleInput (e, param) {
      this.$store.commit('updateTaskParam', { param, value: e });
      if (param === 'kind') {
        this.$store.commit('updateTaskParam', { param: 'position', value: undefined });
      } else if (param === 'chapter') {
        this.themes = bioThemes.filter(bt => bt.chapter === e)[0].themes.sort((a,b) => a.localeCompare(b));
        this.$store.commit('updateTaskParam', { param: 'topic', value: undefined });
        this.$store.commit('updateTaskParam', { param: 'position', value: undefined });
      } else if (param === 'topic') this.$store.commit('updateTaskParam', { param: 'position', value: undefined });

      this.isLoaded = false;
      this.$nextTick(() => this.isLoaded = true);
    },
    async nextTask (remove) {
      if (remove) {
        this.$store.commit('updateTaskParam', { param: 'removed', value: true });
      }
      await this.$store.dispatch('updateTask');
      this.$store.commit('setPreviousTask', this.task._id);
      await Promise.all([
        this.$store.dispatch('getTaskWithMissingInfo', { subject: 'bio' }),
        this.$store.dispatch('updateCount', { subject: 'bio' }),
      ]);
    },
    async prevTask () {
      await this.$store.dispatch('getTaskById', { taskId: this.previousTask });
      this.$store.commit('setPreviousTask', undefined);
    },
  },
};
</script>

<style scoped>
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "task-wrapper info-wrapper";
  }

  .wrapper-task {
    grid-area: task-wrapper;
  }

  .wrapper-info {
    grid-area: info-wrapper;
  }

  .wrapper-info-head {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "basic flags navigation";
  }

  .wrapper-info-basic {
    grid-area: basic;
  }

  .wrapper-info-flags {
    grid-area: flags;
  }

  .wrapper-info-navigation {
    grid-area: navigation;
    justify-self: end;
  }
</style>
