<template>
  <div class="">
    <div v-if="shortView">
      <button class="rounded-xl text-white font-semibold px-4 py-2 m-2"
              :class="`bg-${color}-600`"
              @click="fullView = true">{{ value }}</button>
    </div>
    <div v-else>
      <button v-for="option in fullOptions"
              :key="option"
              class="rounded-xl text-white font-semibold px-4 py-2 m-2"
              :class="fullValue === option ? `bg-${color}-700` : `bg-${color}-500 opacity-80`"
              @click="emit">{{ option }}</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: { type: String, default: 'blue' },
    options: Array,
    value: String,
  },
  data () {
    return {
      fullView: !this.value,
    };
  },
  computed: {
    fullOptions () { return ['—', ...this.options] },
    fullValue () { return this.value ? this.value : '—'; },
    shortView () { return !!this.value && !this.fullView; },
  },
  methods: {
    emit (e) {
      this.fullView = false;
      const option = e.target.innerHTML;
      if (option === '—') return false;
      return this.$emit('input', option);
    },
  },
}
</script>
